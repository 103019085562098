import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';

const ShowGifts = ({ setEditGift }) => {
  const [gifts, setGifts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch gifts from the API
    const fetchGifts = async () => {
      try {
        const response = await axios.get('https://api.invitekahwin.com/api/show-gifts');
        setLoading(false);
        const sortedGifts = response.data.sort((a, b) => b.id - a.id); // Adjust sorting logic if needed
        setGifts(sortedGifts);
      } catch (error) {
        console.error('Error fetching gifts:', error);
      }
    };

    fetchGifts();
  }, []);

  // Handle Delete gift
  const handleDelete = async (id) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this gift?');
    if (!isConfirmed) {
      return; // Exit if the user cancels
    }
  
    try {
      await axios.delete(`https://api.invitekahwin.com/api/delete-gift/${id}`);
      setGifts(gifts.filter(gift => gift.id !== id));
      console.log('Gift deleted successfully');
    } catch (error) {
      console.error('Error deleting gift:', error);
    }
  };
  

  // Handle Edit gift (redirect to edit page, assuming an edit page exists)
  const handleEdit = (id) => {
    window.location.href = `/edit-gift/${id}`;  // Adjust to your routing setup
  };

  return (
    <div className="container mx-auto mt-4">
      {loading ? (
          <div className="flex justify-center items-center h-64">
            <FaSpinner className="text-red-400 text-4xl animate-spin" />
          </div>
        ) : (
          <ul className="space-y-4">
            {gifts.map((gift) => (
              <li key={gift.id} className="bg-white shadow-md rounded-lg p-4 flex flex-col space-y-4 md:flex-row items-center justify-between">
                <div className="flex items-center justify-start w-full">
                  <img
                    src={`https://cdn.invitekahwin.com/images/${gift.image}`}
                    alt={gift.title}
                    className="w-16 h-16 object-cover rounded-md mr-4"
                  />
                  <div>
                    <h2 className="text-xl font-semibold">{gift.title}</h2>
                    <p className="text-gray-500 hidden">{gift.link}</p>
                    <p className="text-lg font-bold text-red-400">RM {gift.price}</p>
                  </div>
                </div>
                <div className="flex justify-between w-full md:w-1/3 space-x-2">
                <button
                  onClick={() => setEditGift(gift.id)} // Set editGift with gift ID
                  className="bg-blue-500 text-white px-4 py-2 w-full rounded-md hover:bg-blue-600"
                >
                  Edit
                </button>
                  <button
                    onClick={() => handleDelete(gift.id)}
                    className="bg-red-500 text-white px-4 py-2 w-full rounded-md hover:bg-pink-600"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
    </div>
  );
};

export default ShowGifts;
