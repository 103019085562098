import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

function Dashboard() {
  const [clientCount, setClientCount] = useState(0);
  const [cardCount, setCardCount] = useState(0);
  const [pastWeddingCount, setPastWeddingCount] = useState(0);
  const [futureWeddingCount, setFutureWeddingCount] = useState(0);
  const [todayWeddingCount, setTodayWeddingCount] = useState(0);
  const [clientsInRange, setClientsInRange] = useState(0);
  const [cardsInRange, setCardsInRange] = useState(0);

  const getMalaysiaDate = () => {
    const date = new Date();
    const malaysiaDate = date.toLocaleDateString('en-CA', { timeZone: 'Asia/Kuala_Lumpur' });
    return malaysiaDate;
  };  

  const [startDate, setStartDate] = useState(getMalaysiaDate());
  const [endDate, setEndDate] = useState(getMalaysiaDate());
  const [startDateCard, setStartDateCard] = useState(getMalaysiaDate());
  const [endDateCard, setEndDateCard] = useState(getMalaysiaDate());

  useEffect(() => {
    totalUsers();
    totalCards();
    totalPastWedding();
    totalFutureWedding();
    totalTodayWedding();
    fetchClientsInRange(startDate, endDate);
    fetchCardsInRange(startDateCard, endDateCard);
  }, []);

  const totalUsers = () => {
    axios.get('https://api.invitekahwin.com/api/totalusers')
      .then(response => {
        setClientCount(response.data.count);
      })
      .catch(error => {
        console.error("There was an error fetching the client count!", error);
      });
  };

  const totalCards = () => {
    axios.get('https://api.invitekahwin.com/api/totalcards')
      .then(response => {
        setCardCount(response.data.count);
      })
      .catch(error => {
        console.error("There was an error fetching the card count!", error);
      });
  };

  const totalPastWedding = () => {
    axios.get('https://api.invitekahwin.com/api/pastweddings')
      .then(response => {
        setPastWeddingCount(response.data.count);
      })
      .catch(error => {
        console.error("There was an error fetching the card count!", error);
      });
  };

  const totalFutureWedding = () => {
    axios.get('https://api.invitekahwin.com/api/futureweddings')
      .then(response => {
        setFutureWeddingCount(response.data.count);
      })
      .catch(error => {
        console.error("There was an error fetching the card count!", error);
      });
  };

  const totalTodayWedding = () => {
    axios.get('https://api.invitekahwin.com/api/todayweddings')
      .then(response => {
        setTodayWeddingCount(response.data.count);
      })
      .catch(error => {
        console.error("There was an error fetching the card count!", error);
      });
  };

  const fetchClientsInRange = (start, end) => {
    axios.get('https://api.invitekahwin.com/api/usersignup/count-by-date', {
      params: {
        start_date: start,
        end_date: end
      }
    })
      .then(response => {
        setClientsInRange(response.data.count);
      })
      .catch(error => {
        console.error("There was an error fetching the clients in range!", error);
      });
  };

  const clientStart = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    fetchClientsInRange(newStartDate, endDate);
  };

  const clientEnd = (event) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
    fetchClientsInRange(startDate, newEndDate);
  };

  const fetchCardsInRange = (start, end) => {
    axios.get('https://api.invitekahwin.com/api/cardscreated/count-by-date', {
      params: {
        start_date: start,
        end_date: end
      }
    })
      .then(response => {
        setCardsInRange(response.data.count);
      })
      .catch(error => {
        console.error("There was an error fetching the cards in range!", error);
      });
  };

  const cardStart = (event) => {
    const newStartDate = event.target.value;
    setStartDateCard(newStartDate);
    fetchCardsInRange(newStartDate, endDate);
  };

  const cardEnd = (event) => {
    const newEndDate = event.target.value;
    setEndDateCard(newEndDate);
    fetchCardsInRange(startDate, newEndDate);
  };

  return (
    <div>
      <Helmet>
        <title>Admin Dashboard | InviteKahwin.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div>
        <h1 className="text-3xl font-bold mb-4">Dashboard</h1>
        <p>Welcome to the admin panel!</p>
      </div>
      <div className='max-w-3xl'>
        {/* Card to show the total number of clients */}
        <div className='grid grid-cols-2 md:grid-cols-5 gap-2 mt-6'>
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h2 className="font-semibold mb-2">Jumlah pengguna</h2>
            <p className="text-xl font-bold text-gray-800">{clientCount}</p>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h2 className="font-semibold mb-2">Jumlah Kad Tercipta</h2>
            <p className="text-xl font-bold text-gray-800">{cardCount}</p>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h2 className="font-semibold mb-2">Sudah Berkahwin</h2>
            <p className="text-xl font-bold text-gray-800">{pastWeddingCount}</p>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h2 className="font-semibold mb-2">Bakal Berkahwin</h2>
            <p className="text-xl font-bold text-gray-800">{futureWeddingCount}</p>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h2 className="font-semibold mb-2">Kahwin Hari Ini</h2>
            <p className="text-xl font-bold text-gray-800">{todayWeddingCount}</p>
          </div>
        </div>
        {/* Card to show the number of clients in the date range */}
        <div className='flex flex-col md:flex-row justify-center md:space-x-2'>
          <div className="w-full md:w-1/2 mt-6 p-6 bg-white rounded-lg shadow-md">
            <h2 className="font-semibold mb-2">Pengguna dalam tempoh masa tertentu</h2>
            <p className="text-xl font-bold text-gray-800">{clientsInRange}</p>
            <div className="mt-4 flex space-x-4">
              <div>
                <label className="block text-gray-700">Start Date</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={clientStart}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div>
                <label className="block text-gray-700">End Date</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={clientEnd}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 mt-6 p-6 bg-white rounded-lg shadow-md">
            <h2 className="font-semibold mb-2">Kad tercipta dalam tempoh masa tertentu</h2>
            <p className="text-xl font-bold text-gray-800">{cardsInRange}</p>
            <div className="mt-4 flex space-x-4">
              <div>
                <label className="block text-gray-700">Start Date</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={cardStart}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div>
                <label className="block text-gray-700">End Date</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={cardEnd}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
