import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";

const ShowPromoCodes = ({ onEdit, reload, email }) => {
  const [promoCodes, setPromoCodes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPromoCodes = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://api.invitekahwin.com/api/promo-codes`,
          {
            params: { email }, // Filter by email
          }
        );
        setPromoCodes(response.data);
      } catch (error) {
        console.error("Error fetching promo codes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPromoCodes();
  }, [reload, email]);

  const today = dayjs();

  // Categorize promo codes
  const availablePromoCodes = promoCodes.filter(
    (promo) =>
      today.isBefore(dayjs(promo.expirationDate)) && promo.claimed < promo.valid
  );

  const expiredPromoCodes = promoCodes.filter((promo) =>
    today.isAfter(dayjs(promo.expirationDate))
  );

  const fullyClaimedPromoCodes = promoCodes.filter(
    (promo) =>
      promo.claimed >= promo.valid &&
      today.isBefore(dayjs(promo.expirationDate))
  );

  if (loading) {
    return <div>Loading promo codes...</div>;
  }

  return (
    <div>
      {/* Available Promo Codes */}
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-2">Available Promo Codes</h2>
        {availablePromoCodes.length > 0 ? (
          <PromoTable promoCodes={availablePromoCodes} onEdit={onEdit} />
        ) : (
          <p>No available promo codes.</p>
        )}
      </div>

      {/* Expired Promo Codes */}
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-2">Expired Promo Codes</h2>
        {expiredPromoCodes.length > 0 ? (
          <PromoTable promoCodes={expiredPromoCodes} onEdit={onEdit} />
        ) : (
          <p>No expired promo codes.</p>
        )}
      </div>

      {/* Fully Claimed Promo Codes */}
      <div>
        <h2 className="text-xl font-bold mb-2">Fully Claimed Promo Codes</h2>
        {fullyClaimedPromoCodes.length > 0 ? (
          <PromoTable promoCodes={fullyClaimedPromoCodes} onEdit={onEdit} />
        ) : (
          <p>No fully claimed promo codes.</p>
        )}
      </div>
    </div>
  );
};

const PromoTable = ({ promoCodes, onEdit }) => (
  <table className="min-w-full border-collapse table-auto">
    <thead>
      <tr className="bg-gray-200">
        <th className="p-2 text-gray-600 font-bold border border-gray-300 text-left">
          Code
        </th>
        <th className="hidden md:table-cell p-2 text-gray-600 font-bold border border-gray-300 text-left">
          Discount
        </th>
        <th className="hidden md:table-cell p-2 text-gray-600 font-bold border border-gray-300 text-left">
          Expiration Date
        </th>
        <th className="hidden md:table-cell p-2 text-gray-600 font-bold border border-gray-300 text-left">
          Claimed/Valid
        </th>
        <th className="p-2 text-gray-600 font-bold border border-gray-300 text-left">
          Actions
        </th>
      </tr>
    </thead>
    <tbody>
      {promoCodes.map((promo) => (
        <tr key={promo.code} className="bg-white border">
          <td className="p-2 border border-gray-300">{promo.code}</td>
          <td className="hidden md:table-cell p-2 border border-gray-300">
            RM{promo.discount}
          </td>
          <td className="hidden md:table-cell p-2 border border-gray-300">
            {dayjs(promo.expirationDate).format("DD MMMM YYYY")}
          </td>
          <td className="hidden md:table-cell p-2 border border-gray-300">
            {promo.claimed}/{promo.valid}
          </td>
          <td className="p-2 border border-gray-300">
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded"
              onClick={() => onEdit(promo)}
            >
              Edit
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default ShowPromoCodes;
