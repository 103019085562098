import React, { useState, useEffect } from 'react';
import { FaHome, FaDollarSign, FaGift, FaLightbulb, FaTag, FaCog, FaSignOutAlt } from 'react-icons/fa';

const Layout = ({ dashboard, sales, recommendedItems, ideaHadiahKahwin, promoCode, settings }) => {
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'Dashboard');

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  const renderContent = () => {
    switch (activeTab) {
      case 'Dashboard':
        return dashboard;
      case 'Sales':
        return sales;
      case 'Recommended Items':
        return recommendedItems;
      case 'Idea Hadiah Kahwin':
        return ideaHadiahKahwin;
      case 'Promo Code':
        return promoCode;
      case 'Settings':
        return settings;
      default:
        return dashboard;
    }
  };

  const logOut = () => {
    localStorage.removeItem('userEmail');
    localStorage.removeItem('token');
    localStorage.removeItem('activeTab');
    window.location.reload();
  };

  return (
    <div className="flex h-full">
      {/* Sidebar for Desktop */}
      <div className="hidden md:flex flex-col w-64 bg-pink-500 text-white sticky top-0 h-screen">
        <div className="p-4 text-2xl font-bold">Admin Panel</div>
        <nav className="flex-grow">
          <ul>
            <li className="p-4 hover:bg-pink-600 cursor-pointer" onClick={() => setActiveTab('Dashboard')}>
              <FaHome className="inline-block mr-2" /> Dashboard
            </li>
            <li className="p-4 hover:bg-pink-600 cursor-pointer" onClick={() => setActiveTab('Sales')}>
              <FaDollarSign className="inline-block mr-2" /> Sales
            </li>
            <li className="p-4 hover:bg-pink-600 cursor-pointer" onClick={() => setActiveTab('Recommended Items')}>
              <FaGift className="inline-block mr-2" /> Recommended Items
            </li>
            <li className="p-4 hover:bg-pink-600 cursor-pointer" onClick={() => setActiveTab('Idea Hadiah Kahwin')}>
              <FaLightbulb className="inline-block mr-2" /> Idea Hadiah Kahwin
            </li>
            <li className="p-4 hover:bg-pink-600 cursor-pointer" onClick={() => setActiveTab('Promo Code')}>
              <FaTag className="inline-block mr-2" /> Promo Code
            </li>
            <li className="p-4 hover:bg-pink-600 cursor-pointer" onClick={() => setActiveTab('Settings')}>
              <FaCog className="inline-block mr-2" /> Settings
            </li>
            <li className="p-4 hover:bg-pink-600 cursor-pointer" onClick={() => logOut()}>
              <FaSignOutAlt className="inline-block mr-2" /> Logout
            </li>
          </ul>
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-grow min-h-screen bg-gray-100 px-4 py-12 md:p-12 mb-12 md:mb-0">
        {renderContent()}
      </div>

      {/* Bottom Tabs for Mobile */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 bg-pink-500 text-white flex justify-around w-full">
        <div className="py-4 cursor-pointer" onClick={() => setActiveTab('Dashboard')}>
          <FaHome size={24} />
        </div>
        <div className="py-4 cursor-pointer" onClick={() => setActiveTab('Sales')}>
          <FaDollarSign size={24} />
        </div>
        <div className="py-4 cursor-pointer" onClick={() => setActiveTab('Idea Hadiah Kahwin')}>
          <FaGift size={24} />
        </div>
        <div className="py-4 cursor-pointer" onClick={() => setActiveTab('Recommended Items')}>
          <FaLightbulb size={24} />
        </div>
        <div className="py-4 cursor-pointer" onClick={() => setActiveTab('Promo Code')}>
          <FaTag size={24} />
        </div>
        <div className="py-4 cursor-pointer" onClick={() => setActiveTab('Settings')}>
          <FaCog size={24} />
        </div>
        <div className="py-4 cursor-pointer" onClick={() => logOut()}>
          <FaSignOutAlt size={24} />
        </div>
      </div>
    </div>
  );
};

export default Layout;
