import React from 'react';
import Layout from '../components/Layout';
import AdminDashboard from './Dashboard';
import Sales from './Sales';
import RecommendedItems from './RecommendedItems';
import IdeaHadiahKahwin from './IdeaHadiahKahwin';
import PromoCode from './PromoCode';
import Settings from './Settings';

function Main() {
  return (
    <Layout
      dashboard={<AdminDashboard />}
      sales={<Sales />}
      recommendedItems={<RecommendedItems />}
      ideaHadiahKahwin={<IdeaHadiahKahwin />}
      promoCode={<PromoCode />}
      settings={<Settings />}
    />
  );
}

export default Main;
