import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaAngleLeft } from 'react-icons/fa';

const EditGift = ({ setEditGift, giftId }) => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(''); // New state for image preview
  const [link, setLink] = useState('');
  const [price, setPrice] = useState('');

  useEffect(() => {
    const fetchGift = async () => {
      try {
        const response = await axios.get(`https://api.invitekahwin.com/api/show-gifts/${giftId}`);
        const gift = response.data;
        setTitle(gift.title);
        setImage(gift.image);
        setLink(gift.link);
        setPrice(gift.price);
        setImagePreview(`https://cdn.invitekahwin.com/images/${gift.image}`);
      } catch (error) {
        console.error('Error fetching gift data:', error);
      }
    };

    fetchGift();
  }, [giftId]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImagePreview(URL.createObjectURL(file)); // Update preview with the new image
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    if (image && typeof image === 'object') { // Check if image is a file object
      formData.append('image', image);
    }
    formData.append('link', link);
    formData.append('price', price);

    try {
      const response = await axios.post(`https://api.invitekahwin.com/api/update-gift/${giftId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Gift updated:', response.data);
      window.location.reload();
    } catch (error) {
      console.error('Error updating gift:', error);
    }
  };

  return (
    <div className="">
      <div className='flex items-center'>
        <button onClick={() => setEditGift(false)}>
          <FaAngleLeft className='text-4xl mr-4' />
        </button>
        <h1 className="text-3xl font-bold mb-4">Edit Gift</h1>
      </div>
      <form onSubmit={handleSubmit} className="max-w-3xl">

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
            placeholder="Enter gift title"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Image (optional)</label>
          {imagePreview ? (
            <div>
              <img
                src={imagePreview}
                alt={title}
                className="w-32 h-32 object-cover rounded-md mr-4"
              />
              <button className='text-blue-600' onClick={() => setImagePreview(null)}>Change image</button>
            </div>
          ) : (
            <input
              type="file"
              onChange={handleImageChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
            />
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Link</label>
          <input
            type="url"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
            placeholder="Enter external URL"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Price</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
            placeholder="Enter gift price"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-pink-500 text-white font-medium py-2 px-4 rounded-md hover:bg-pink-700 transition-colors duration-200"
        >
          Update Gift
        </button>
      </form>
    </div>
  );
};

export default EditGift;
