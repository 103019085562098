import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import AddGifts from './AddGifts';
import EditGift from './EditGift';
import ShowGifts from '../components/ShowGifts';

function IdeaHadiahKahwin() {  
  const [addGift, setAddGift] = useState(false);
  const [editGift, setEditGift] = useState(null); // New state for editing

  return (
    <div>
      <Helmet>
        <title>Idea Hadiah Kahwin | InviteKahwin.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {addGift ? (
        <AddGifts setAddGift={setAddGift} />
      ) : editGift ? (
        <EditGift giftId={editGift} setEditGift={setEditGift} /> // Pass the gift ID to EditGift
      ) : (
        <div className=''>
          <div className='flex items-center justify-between'>
            <div>
              <h1 className="text-3xl font-bold mb-4">Idea Hadiah Kahwin</h1>
              <p>Senarai Idea Hadiah Kahwin!</p>
            </div>
            <button onClick={() => setAddGift(true)} className='text-white bg-pink-500 hover:bg-pink-700 p-2 md:px-5 rounded-lg'>
              Add Gift
            </button>
          </div>
          <ShowGifts setEditGift={setEditGift} /> {/* Pass setEditGift to ShowGifts */}
        </div>
      )}
    </div>
  );
}

export default IdeaHadiahKahwin;
