import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './pages/Main';
import AddItems from './pages/AddItems';
import AuthRoute from './components/AuthRoute';
import Login from './pages/Login';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AuthRoute component={Main} />} />
        <Route path="/login" element={<AuthRoute component={Login} />} />
      </Routes>
    </Router>
  );
}

export default App;
