import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PromoCodes from '../components/PromoCodes';

function PromoCode() {  

  return (
    <div>
      <Helmet>
        <title>Promo Code | InviteKahwin.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div>
        <h1 className="text-3xl font-bold mb-4">Promo Code</h1>
        <p>Welcome to the Promo Code!</p>
        <PromoCodes />
      </div>
    </div>
  );
}

export default PromoCode;
